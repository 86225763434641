#main-menu {
  z-index: 10;
  display: none;
  position: fixed;
  inset: 100px 0 0;
}

#main-menu.visible {
  display: block;
}

#main-menu #main-menu-inner {
  max-width: 1080px;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0 32px;
  display: flex;
}

#main-menu a {
  color: #fff;
  font-family: Righteous, sans-serif;
  font-size: 80px;
  line-height: 100px;
  text-decoration: none;
  transition: all .3s;
  animation-name: slideIn;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  position: relative;
  transform: translateY(100vh)translateX(100vw);
}

#main-menu a:hover {
  color: #9e7509;
}

#main-menu a.selected {
  color: #9e7509;
  font-size: 100px;
  line-height: 120px;
}

#main-menu a.selected:before, #main-menu a.selected:after {
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #9e7509;
  display: block;
}

#main-menu a:nth-child(2) {
  margin-left: 180px;
  animation-delay: 50ms;
}

#main-menu a:nth-child(3) {
  margin-left: 270px;
  animation-delay: .1s;
}

#main-menu a:nth-child(4) {
  margin-left: 180px;
  animation-delay: .15s;
}

#main-menu a:nth-child(5) {
  margin-left: 270px;
  animation-delay: .2s;
}

@keyframes slideIn {
  from {
    transform: translateY(100vh)translateX(100vw);
  }

  to {
    transform: translateY(0)translateX(0);
  }
}

#page-header.page-scrolled ~ #main-menu {
  top: 80px;
}

@media (max-width: 1140px) {
  #main-menu {
    top: 60px !important;
  }

  #main-menu a {
    font-size: 36px;
    line-height: 45px;
  }

  #main-menu a.selected {
    font-size: 48px;
    line-height: 60px;
  }

  #main-menu a:nth-child(2) {
    margin-left: 30px;
  }

  #main-menu a:nth-child(3) {
    margin-left: 60px;
  }

  #main-menu a:nth-child(4) {
    margin-left: 30px;
  }

  #main-menu a:nth-child(5) {
    margin-left: 60px;
  }
}

/*# sourceMappingURL=index.3686f6d7.css.map */
