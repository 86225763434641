@import "constants";

#main-menu {
  position: fixed;
  top: 100px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: none;

  &.visible {
    display: block;
  }

  #main-menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    max-width: $main-content-width;
    margin: 0 auto;
    height: 100%;
    padding: 0 32px;
  }

  a {
    position: relative;
    font-family: $header-font;
    font-size: 80px;
    line-height: 100px;
    color: white;
    text-decoration: none;
    transition: $animation-time all;
    transform: translateY(100vh) translateX(100vw);
    animation-fill-mode: forwards;
    animation-duration: 500ms;
    animation-name: slideIn;

    &:hover {
      color: $gold;
    }

    &.selected {
      font-size: 100px;
      line-height: 120px;
      color: $gold;

      &:before,
      &:after {
        display: block;
        content: " ";
        width: 100%;
        height: 1px;
        background-color: $gold;
      }
    }

    &:nth-child(2) {
      margin-left: 180px;
      animation-delay: 50ms;
    }

    &:nth-child(3) {
      margin-left: 270px;
      animation-delay: 100ms;
    }

    &:nth-child(4) {
      margin-left: 180px;
      animation-delay: 150ms;
    }

    &:nth-child(5) {
      margin-left: 270px;
      animation-delay: 200ms;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100vh) translateX(100vw);
  }

  to {
    transform: translateY(0) translateX(0);
  }
}

#page-header.page-scrolled ~ #main-menu {
  top: 80px;
}

@media (max-width: $mobile-screen-width) {
  #main-menu {
    top: 60px !important;

    a {
      font-size: 36px;
      line-height: 45px;

      &.selected {
        font-size: 48px;
        line-height: 60px;
      }

      &:nth-child(2) {
        margin-left: 30px;
      }

      &:nth-child(3) {
        margin-left: 60px;
      }

      &:nth-child(4) {
        margin-left: 30px;
      }

      &:nth-child(5) {
        margin-left: 60px;
      }
    }
  }
}
